import React, { ChangeEvent, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import { Switch } from '../../components/common/Switch';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Radio } from '../../components/common/Radio';
import { FormError } from '../../components/common/FormError';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import { IdentityConfirmationSourceType } from '../../types/enums/IdentityConfirmationSourceType';
import useIdentityVerificationHelpers from './helpers';
import { isMojeIdEnabled } from '../../utils';
import { useContextHelpers } from '../../utils/hooks';
import { AppContext } from '../../components/Context';
import { ParticipantPersonProps, PersonalIdentificationFullProps } from '../../types/model';

interface IdentityVerificationFormProps {
    values: any;
    disabled?: boolean;
}

const IdentityVerificationForm = ({ values, disabled = false }: IdentityVerificationFormProps) => {
    const { t } = useTranslation();
    const { showPopup, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const formikContext = useFormikContext();
    const {
        ownerParticipant,
        otherParticipants,
        isAnyCardsDataEnter,
        isAnyPersonalDataEnter,
        isIdentificationDocumentPhoto,
    } = useContextHelpers();
    const pageTranslation: any = t('pages.identityVerification', { returnObjects: true });
    const { isDeclarationRequired, isPersonalDataRequired, isPersonalDataSimpleRequired, isConfirmatorTypeRequired } =
        useIdentityVerificationHelpers();

    const mojeIdProps = !isMojeIdEnabled()
        ? {
              isDisabled: true,
              helpText: pageTranslation.card[1].mojeId.tooltip,
          }
        : {};

    const handleSelectIdentityVerificationType = async (e: ChangeEvent<HTMLInputElement>) => {
        if ((isAnyCardsDataEnter || isAnyPersonalDataEnter) && isIdentificationDocumentPhoto) {
            showPopup('change-identity-verification-type-warning', () => {
                if (data) {
                    const updatedParticipant = {
                        ...ownerParticipant,
                    } as ParticipantPersonProps<PersonalIdentificationFullProps>;
                    if (updatedParticipant?.Identification) {
                        updatedParticipant.Identification.IdCard = null;
                        updatedParticipant.Identification.PersonalIdentificationDetail = null;
                        updatedParticipant.Identification.PermanentAddress = null;
                    }
                    const newData = {
                        ...data,
                        Participants: [updatedParticipant, ...(otherParticipants || [])],
                    };
                    ctx.setCurrentModel(newData);
                }
                formikContext.setFieldValue(e.target.name, e.target.value);
            });
        } else {
            formikContext.setFieldValue(e.target.name, e.target.value);
        }
    };

    return (
        <div className="flex flex-col gap-y-10">
            {isDeclarationRequired && (
                <Card data-test="declarationCard">
                    <Title tag="h2" size="md" fontWeight="medium" className="mb-8" data-test="declarationCardTitle">
                        {pageTranslation.card[0].title}
                    </Title>
                    <Field
                        name="declaration"
                        label={pageTranslation.card[0].declaration.title}
                        component={Switch}
                        data-test="declaration"
                    />
                </Card>
            )}

            {isPersonalDataRequired(values) && (
                <Fragment>
                    <Card data-test="personalDataCard">
                        <Title
                            tag="h2"
                            size="md"
                            fontWeight="medium"
                            className="mb-8"
                            data-test="personalDataCardTitle"
                        >
                            {pageTranslation.card[1].title}
                        </Title>

                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].mojeId.title}
                            value={IdentityConfirmationSourceType.MojeId.toString()}
                            component={Radio}
                            type="radio"
                            className="mb-8"
                            isDisabled={disabled}
                            {...mojeIdProps}
                            onChange={handleSelectIdentityVerificationType}
                            data-test="personalDataMojeId"
                        />
                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].takingSnap.title}
                            value={IdentityConfirmationSourceType.Photocopy.toString()}
                            component={Radio}
                            type="radio"
                            isDisabled={disabled}
                            onChange={handleSelectIdentityVerificationType}
                            data-test="personalDataPhotocopy"
                        />
                        <FormError name="personalData" className="mt-2" data-test="personalDataError" />
                    </Card>

                    {isConfirmatorTypeRequired(values) && (
                        <Card data-test="phototocopyCard">
                            <Title tag="h2" size="md" fontWeight="medium" className="mb-8" data-test="phototocopyTitle">
                                {pageTranslation.card[2].title}
                            </Title>

                            <Field
                                name="confirmatorType"
                                label={pageTranslation.card[2].myDevice.title}
                                value={IdentityConfirmatorType.Agent.toString()}
                                component={Radio}
                                type="radio"
                                className="mb-8"
                                data-test="confirmatorTypeAgent"
                            />
                            <Field
                                name="confirmatorType"
                                label={pageTranslation.card[2].clientDevice.title}
                                value={IdentityConfirmatorType.Client.toString()}
                                component={Radio}
                                type="radio"
                                data-test="confirmatorTypeClient"
                            />
                            <FormError name="confirmatorType" className="mt-2" data-test="confirmatorTypeError" />
                        </Card>
                    )}
                </Fragment>
            )}

            {isPersonalDataSimpleRequired(values) && (
                <>
                    <Card data-test="personalDataCard">
                        <Title
                            tag="h2"
                            size="md"
                            fontWeight="medium"
                            className="mb-8"
                            data-test="personalDataCardTitle"
                        >
                            {pageTranslation.card[1].title}
                        </Title>

                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].mojeId.title}
                            value={IdentityConfirmationSourceType.MojeId.toString()}
                            component={Radio}
                            isDisabled={disabled}
                            type="radio"
                            {...mojeIdProps}
                            className="mb-8"
                            onChange={handleSelectIdentityVerificationType}
                            data-test="personalDataMojeId"
                        />
                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].checkAgent.title}
                            value={IdentityConfirmationSourceType.Photocopy.toString()}
                            component={Radio}
                            type="radio"
                            isDisabled={disabled}
                            onChange={handleSelectIdentityVerificationType}
                            data-test="personalDataPhotocopy"
                        />
                        <FormError name="personalData" className="mt-2" data-test="personalDataError" />
                    </Card>
                </>
            )}
        </div>
    );
};

export default IdentityVerificationForm;
